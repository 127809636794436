// extracted by mini-css-extract-plugin
export var wrapper = "Category_Static-module--wrapper--FBkyQ";
export var sortByPagination = "Category_Static-module--sortByPagination--8Defh";
export var mobileFilterBtn = "Category_Static-module--mobileFilterBtn--cvLB6";
export var subWrapper = "Category_Static-module--subWrapper--j4ghZ";
export var numOfItemsWrapper = "Category_Static-module--numOfItemsWrapper--INHu7";
export var collectionsWrapper = "Category_Static-module--collectionsWrapper--fRMPl";
export var placeHolderWrap = "Category_Static-module--placeHolderWrap--445rc";
export var mainImage = "Category_Static-module--mainImage--LPXEY";
export var otherImage = "Category_Static-module--otherImage--SW+f5";
export var wrapper2 = "Category_Static-module--wrapper2--F0EOX";